.slider {
    position: relative;
    min-height: 180px;
    width: 100%;
    @include bdown(md) {
        min-height: 0px;
    }
    
}
.slick-slide {
    &:focus {
        outline: none;
    }
}
.slick-track {
    display: flex;
    // align-items: center;
    height: inherit;
}
.slider-instance {
    &.init {
        opacity: 0;
        height: 80px;
    }
}
.slick-dots {
    display: block;
    li {
        display: inline-block;
        padding: 0 7px;
    }
    .slick-dot {
        width: 10px;
        height: 10px;
        background-color: $blue;
        border-radius: 50%;
        position: relative;
        &:before {
            content: "";
            display: block;
            width: 22px;
            height: 22px;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            border: 2px solid transparent;
            position: absolute;
            transform: translate(-50%, -50%);
            
        }
        &:hover {
            background-color: $light-blue;
        }
    }
    .slick-active {
        .slick-dot {
            background-color: $light-blue;
            &:before {
                border-color: $light-blue;
            }
        }
    }
}
.main-slider {
    @include transition;
    border-bottom: .73rem solid $green;
    &#middle-slider {
        border-bottom: none;
    }
    &.slider-instance.init {
        height: 400px;
    }
    @extend %slider-arrow-style-1;
    .slick-dots {
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);
    }
    .slider-item {
        position: relative;
        background-size: cover !important;
        background-position: center top;
        background-repeat: no-repeat;
        height: inherit;
        min-height: 30vh;
        max-height: 100vh;
        &.position-left {
            &:after {
                display: block;
                content: "";
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background-image: linear-gradient(to right, rgba(0, 0, 0, .99), rgba(0, 0, 0, .42) 50%, transparent);

            }
        }
        &.position-right {
            &:after {
                display: block;
                content: "";
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background-image: linear-gradient(to right, rgba(0, 0, 0, .99), rgba(0, 0, 0, .42) 50%, transparent);
                @include bup(md){
                    background-image: linear-gradient(to left, rgba(0, 0, 0, .99), rgba(0, 0, 0, .42) 50%, transparent);
                }
            }
            .description .inner {
                @include bup(md){
                    right: 0;
                }
                @include bbetween(md, lg){
                    right: 15%;
                    left: auto;
                }
            }
        }
    }
    .description {
        position: absolute;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        min-width: 33%;
        height: 100%;
        max-width: 1400px;
        width: 100%;
        z-index: 15;
        .inner {
            position: absolute;
            // width: 47%;
            // top: 160px;
            // height: calc(100% - 160px);
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .slider-title-block {
            @extend %title-block;
            // padding-top: 1rem;
            line-height: 1;
            // background-color: rgba($color: $green, $alpha: .81);
            color: #fff;
        }
        // .slider-buttons-block {
        //     margin-top: .5em;
        // }
        .btn {
            background-color: $blue;
            &:hover {
                background-color: $green;
            }
        }
    }
    
    
    @include bup(md) {
        &.no-min-height {
            .slider-item {
                min-height: 0;
            }
        }
    }
    @include bup(sm) {
        .description {
            .slider-title-block {
                p {
                    max-width: 610px;
                }
            }
        }
    }
    @media screen and (max-width: 1400px){
        .description {
            .inner {
                left: 15%;
            }
        }
    }
    @include bdown(md) {
        .slider-item {
            min-height: 60vh;
        }
        .description {
            .inner {
                width: auto;
                left: 10%;
                right: 10%;
            }
        }
        &.no-min-height {
            .slider-item {
                min-height: 200px;
            }
        }
    }
    @include bdown(sm) {
        .description {
            .inner {
                padding: 1em 0;
            }
            .slider-buttons-block {
                margin-top: 0;
                .slider-button {
                    margin: .3em 0;
                    padding: .5em 1em;
                }
            }
        }
    }
    @include bdown(xs) {
        .description {
            .inner {
                left: 5%;
                right: 5%;
            }
            .slider-title-block {
                .title-2 {
                    padding-top: .15em;
                    padding-bottom: .15em;
                }
                p {
                    margin-bottom: 1em;
                }
            }
        }
        .slick-arrow {
            display: none !important;
        }
    }
}
.two-parts-slider {
    @extend %slider-arrow-style-1;
    .slick-slide.slider-item {
        height: inherit;
    }
    .inner {
        display: flex;
        height: 100%;
        &-text {
            flex: 1 1 45%;
            padding: 7.8rem 5% 6rem 10%;
            background-color: $dark-blue;
        }
        &-image {
            flex: 1 1 55%;
            position: relative;
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }
        .title-1 {
            color: #fff;
            @include fluid-font(26px);
            @include letter-spacing(26);
            line-height: 1;
            &:before {
                content: url(../../images/icons/t-left.svg);
                margin-right: .8em;
            }
            display: flex;
            align-items: center;
        }
        .title-2 {
            font-family: $raj;
            font-weight: 500;
            @include font-count(40, 90);
            color: $light-blue;
            line-height: 1;
        }
        p {
            margin-bottom: 0;
            color: #fff;
        }
        .slider-buttons-block {
            margin-top: 3rem;
        }
    }
    .position-right {
        .inner {
            flex-direction: row-reverse;
            &-text {
                padding: 7.8rem 10% 6rem 5%;
            }
        }
    }
    &.alt-middle {
        .inner {
            &-text {
                padding-top: 5rem;
                padding-bottom: 5rem;
            }
        }
        // .slick-dots {
        //     left: calc(55% + 5rem);
        // }
    }
    &.alt-small {
        .inner {
            &-text {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
        }
        // .slick-dots {
        //     left: calc(55% + 3rem);
        // }
    }
    .slick-dots {
        position: absolute;
        left: 10%;
        bottom: 1rem;
    }
    @include bdown (md){
        .inner {
            flex-direction: column;
            &-text {
                padding: 2.5rem 5%;
            }
            img {
                position: static;
            }
        }
        .position-right {
            .inner {
                flex-direction: column;
                &-text {
                    padding: 2.5rem 5%;
                }
                img {
                    position: static;
                }
            }
        }
        &.alt-middle,
        &.alt-small {
            .inner {
                flex-direction: column;
                &-text {
                    padding: 2.5rem 5%;
                }
                img {
                    position: static;
                }
            }
        }
    }
}

.testimonials-container {
    .container.fw {
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
}
.testimonials-slider {
    @include bup(md) {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 5%;
        padding-right: 3%;
        max-width: 1230px;
        margin-left: auto;
        margin-right: auto;
    }
    @include bdown(md) {
        padding-top: 25px;
        padding-bottom: 0;
        .inner {
            padding-left: 5%;
            padding-right: 5%;
            margin-left: 5%;
            margin-right: 5%;
        }
        .testimonial {
            padding-left: 5em;
        }
    }
    .inner {
        padding: 0 2em;
        margin-left: 2px;
        margin-right: 2px;
        position: relative;
    }
    .testimonial {
        padding-top: 18px;
        padding-left: 8em;
        position: relative;
        p {
            @include fluid-font(19px);
        }
        &:before {
            content: "\e93c";
            @include font-count(30, 70);
            display: block;
            position: absolute;
            left: 0;
            font-family: "icomoon";
            color: $blue;
        }
    }
    .ts {
        &-author-position {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        &-author {
            font-family: $sacramento;
            @include fluid-font(38px);
            color: #505050;
            margin: 0;
        }
        &-position {
            @include fluid-font(14px);
            color: $blue;
            margin: 0;
            &:before {
                padding-left: 1rem;
                padding-right: .5rem;
                content: "|";
                display: inline-block;
            }
        }
    }
    .slick-prev.icon,
    .slick-next.icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        color: #D2D2D2;
        z-index: 100;
        color: $gray;
        &:before {
            @include transition;
            display: inline-block;
            text-align: center;
            @include font-count(20, 38);
        }
        &:hover {
            color: $blue;
        }
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
    .slick-dots {
        padding-left: calc(10em - 5px);
        @include bdown(md) {
           padding-left: calc(8em - 5px);
        }
    }
}
.packages {
    &--inner {
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding-top: 8rem;
        img {
            position: absolute;
            bottom: calc(100% - 7rem);
            max-height: 7rem;
            left: 1.2rem;
        }
    }
    &--slider {
        @include bup(md){
            padding-left: 7%;
            padding-right: 7%;
        }
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    &--info {
        border-left: 1px solid $blue;
        padding-left: 1rem;
        h5 {
            color: $blue;
            @include fluid-font(25px);
        }
        p {
            @include fluid-font(20px);
        }
        a {
            @include fluid-font(15px);
            color: $orange;
            font-weight: 600;
            &:after {
                content: "\e90a";
                font-family: $icomoon;
                padding-left: .5rem;
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 2rem;
        color: $blue;
        &:hover {
            color: $light-blue;
        }
        &.slick-prev {
            left: 1rem;
        }
        &.slick-next {
            right: 1rem;
        }
        @include bdown(xs){
            &.slick-prev {
                left: .5rem;
            }
            &.slick-next {
                right: .5rem;
            }
        }
    }

}
.learn-how {
    &--inner {
        max-width: 95%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        img {
           
        }
    }

    &--slider {
        @include bup(md){
            padding-left: 7%;
            padding-right: 7%;
        }
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    &--info {
        padding-left: 1rem;
        padding-top: 1rem;
        h5 {
            color: $blue;
            @include fluid-font(37px);
            font-weight: 700;
            color: $dark;
            line-height: 1;
        }
        p {
            @include fluid-font(20px);
            text-transform: uppercase;
            color: $blue;
            font-weight: 600;
            margin-bottom: 0;
        }
        a {
            @include fluid-font(15px);
            color: $orange;
            font-weight: 600;
            &:after {
                content: "\e90a";
                font-family: $icomoon;
                padding-left: .5rem;
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 2rem;
        color: $blue;
        &:hover {
            color: $light-blue;
        }
        &.slick-prev {
            left: 1rem;
        }
        &.slick-next {
            right: 1rem;
        }
        @include bdown(xs){
            &.slick-prev {
                left: .5rem;
            }
            &.slick-next {
                right: .5rem;
            }
        }
    }

}