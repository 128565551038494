.question-popup {
    display: none;
}
.question-popup-lightbox {
    .question-popup {
        display: block;
        color: #fff;
        
        &--top {
            height: 2rem;
            position: relative;
            z-index: 10;
            img {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        &--bottom {
            padding: 3rem 1rem 1rem;
            background-image: url(../../images/repeat-grid.jpg);
            background-repeat: repeat;
            position: relative;
            @include bup(md){
                padding-left: 10%;
                padding-right: 10%;
            }
            .icon-close {
                position: absolute;
                right: 0;
                top: 0;
                padding: .9rem;
                display: flex;
                align-items: center;
                background-color: $dark-blue;
                font-size: 1.5rem;
                color: #fff;
                &:before {
                    @include transition;
                    padding-right: 0;
                }
                &:hover:before {
                    transform: rotate(180deg);
                }
            }
        }
        h2 {
            @include fluid-font(46px);
        }
        button.btn {
            background-color: transparent;
            color: $dark-blue;
            font-weight: 600;
            @include letter-spacing(50);
            @include fluid-font(20px);
            padding: .5rem 2rem;
            &:hover {
                background-color: #fff;
            }
        }
    }
    .featherlight-content {
        background: transparent;
        border: transparent;
        @include bup(md){
            width: 855px;
        }
        @include bdown(md){
            width: 70%;
            min-width: 300px;
        }
    }
}
.call-to-action {
    font-family: $raj;
    position: relative;
    z-index: 5;
    &:before {
        position: absolute;
        content: "";
        display: block;
        height: 3px;
        top: -5px;
        left: 0;
        width: 100%;
        background-color: $blue;
    }
    .inner {
        display: flex;
        background-color: #313131;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 1082px;
        position: absolute;
        align-items: center;
        text-align: center;
        &:before,
        &:after {
            display: inline-flex;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &--text {
        color: $light-blue;
        @include fluid-font(40px);
        font-weight: 600;
    }
    &--link {
        color: #fff;
        @include fluid-font(48px);
        font-weight: 700;
        &:hover {
            color: $light-blue;
        }
    }
    @include bup(md){
        .inner {
            padding: 1rem 5rem;
            &:before {
                content: url(#{$images}/icons/t-right.svg);
                padding-right: .5rem;
                left: 0;
            }
            &:after {
                content: url(#{$images}/icons/t-left.svg);
                padding-left: .5rem;
                right: 0;
            }
        }
        &--text {
            padding-right: .5rem;
        }
        &--text,
        &--link {
            white-space: nowrap;
        }
    }
    @include bdown(md) {
        .inner {
            padding: 1rem 0;
            width: 90%;
            display: block;
            justify-content: center; 
        }
    }
}
.metro {
    flex-wrap: wrap;
    justify-content: flex-start;
}
.services-metro {
    &--item {
        @include column(30%, 1.5rem, 1%, 2, 1 );
        background-color: #fff;
        padding: .5rem 1rem;
        display: flex;
        align-items: center;
        font-family: $raj;
        font-weight: 600;
        color: #0D2B70;
        @include fluid-font(31px);
        border: 3px solid #C9C9C9;
        &:hover {
            background-color: $light-blue;
        }
    }
    &--img {
        margin-right: 1rem;
    }
}
.service-item-metro {
    display: flex;
    li {
        @include column(35%, 2rem, 7.5%, 2, 1 );
        padding-left: 20px;
        position: relative;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: #117FB9;
            width: 10px;
        }
    }
}
.about-us-metro {
    &--item {
        @include column(23%, 1.5rem, 0%, 2, 1 );
        padding: .5rem;
        display: flex;
    }
    &--img {
        margin-right: 1rem;
        width: 25%;
        flex: 0 0 25%;
        display: flex;
        flex-direction: column;
        img {
            min-height: 93px;
        }
        &:after {
            content: "";
            background-image: url(#{$images}/icons/t-long-top.svg);
            background-repeat: no-repeat;
            display: block;
            flex: 1 1 auto;
            background-position: center top;
            background-size: contain;
            margin-top: .5rem;
        }
    }
    &--info {
        flex: 1 1 auto;
    }
    &--header {
        font-family: $raj;
        font-weight: 600;
        color: #0D2B70;
        @include fluid-font(31px);
        margin-bottom: 1rem;
        margin-top: 5rem;
    }
    &--text {
        @include fluid-font(22px);
        @include rlh(44px);
    }
}
.team-metro {
    .container {
        max-width: 1500px;
    }
    &--item {
        @include column(30%, 1%, 1%, 2, 1 );
        padding: .5rem;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
        }
    }
    &--img-container {
        position: relative;
        height: 386px;
    }
    &--info {
        background-color: $dark-blue;
        text-align: center;
        padding: 1rem;
    }
    &--header {
        color: #E3F2FB;
        @include fluid-font(31px);
        font-family: $raj;
        font-weight: 600;
        @include letter-spacing(44);
    }
    &--text {
        color: #A3D4FF;
        @include letter-spacing(20);
        @include fluid-font(26px);
    }
}
section.our-news {
    .our-news-card {
        display: block !important;
        overflow: hidden;
        height: 100%;
        background-color: #fff;
        &-inner {
            background-color: #fff;
            padding: .5rem;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        &-title {
            @include fluid-font(28px);
            font-weight: 700;
            color: $blue;
            padding: 1rem 0;
            line-height: 1;
            box-sizing: content-box;
            display: flex;
            align-items: center;
            @include transition;
            @include bup(md){
                min-height: 70px;
            }
        }
        &-excerpt {
            color: #5F5F5F;
            margin-bottom: .7rem;
            @include fluid-font(16px);
            @include rlh(25px);
        }
        &-date {
            color: #fff;
            background-color: $dark-blue;
            @include fluid-font(15px);
            @include transition;
            padding: .5rem;
            &:before {
                content: "\e907";
                display: inline-block;
                padding-right: 1em;
                font-family: $icomoon;
            }
        }
        &-media-wrapper {
            position: relative;
            height: 0;
            padding-bottom: (9 * 100% / 16);
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &-rm {
            text-transform: uppercase;
            color: $blue;
            font-weight: 600;
            &:after {
                font-family: icomoon;
                display: inline-block;
                content: "\e90a";
                opacity: 0;
                padding-left: 10px;
                transform: translateX(-10px);
                @include transition;
            }
        }
        &:hover {
            .our-news-card-rm:after {
                opacity: 1;
                transform: translateX(0);
            }
            .our-news-card-title {
                color: $light-blue;
            }
            .our-news-card-date {
                background-color: $light-blue;
            }
        }
    }
}
.link-more {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-family: $raj;
    color: $dark-blue;
    font-weight: 600;
    @include letter-spacing(50);
    &:after {
        font-family: $icomoon;
        content: url(../../images/icons/t-right.svg);
        display: inline-block;
        margin-left: .5rem;
    }
    &:hover {
        color: $light-blue;
    }
    &--left,
    &--right {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    &--right {
        right: 15px;
    }
    &--left {
        left: 15px;
    }
    @include bdown(md){
        position: static;
    }
}
.back-link {
    color: #A2A0A0;
    &:before {
        color: $blue;
    }
    &:hover {
        color: $blue;
    }
}
.num-container {
    .inner {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .num {
        @include fluid-font(103px);
        color: #A3D4FF;
        font-weight: 500;
        font-family: $raj;
        margin-right: 1rem;
    }
    .num-info {
        span {
            display: block;
        }
        &--top {
            color: #fff;
            font-family: $cairo;
            @include fluid-font(22px);
        }
        &--bottom {
            font-weight: 600;
            font-family: $raj;
            color: #A3D4FF;
            @include fluid-font(40px);
        }
    }
    @include bdown(md){
        flex-wrap: wrap;
        .col {
            flex: 0 0 50%;
        }
        .inner {
            flex-direction: column;
            padding: 5px;
            .num {
                margin-right: 0;
            }
            .num-info {
                text-align: center;
            }
        }
    }
    @include bdown(xxs){
        .col {
            flex: 0 0 100%;
        }
    }
}
.hor-block {
    background-color: #fff;
    border: 3px solid #C9C9C9;
    margin-bottom: 2rem;
    margin-left: 0;
    margin-right: 0;
    .col {
        padding: 0;
    }
    &--media-wrapper {
        position: relative;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }
    &--inner {
        padding: .5rem 1rem 2rem;
    }
    &--title {
        display: flex;
        align-items: flex-end;
        margin-bottom: 1rem;
        h3 {
            border-bottom: 3px solid #117FB9;
            margin-left: 1rem;
        }
    }
    .link-more {
        &:before {
            content: url(../../images/icons/t-right.svg);
            display: inline-block;
            margin-right: .5rem;
        }
        &:after {
            content: none;
        }
    }
    &:nth-child(even){
        flex-direction: row-reverse;
    }
}
.question-line {
    position: relative;
    margin-top: -1.3rem;
    margin-bottom: -1.3rem;
    // background-color: #17254B;
    &.alt {
        margin-bottom: 4rem;
        &:before {
            background-color: #444;
        }
    }

    &:before {
        content: "";
        background-color: #17254B;
        position: absolute;
        left: 0;
        width: 100%;
        top: 13%;
        height: 74%;
        display: block;
        z-index: -1;
    }
    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .question {
            font-family: $raj;
            @include fluid-font(56px);
            color: #fff;
            font-weight: 700;
        }
    }
    @include bup(md){
        .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 0 1 66.66%;
        }
    }
    @include bdown(md){
        margin-top: 0rem;
        margin-bottom: 0rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        &:before {
            top: 0;
            height: 100%;
        }
        .inner {
            flex-wrap: wrap;
            img {
                flex: 1 1 30%;
            }
            .question {
                flex: 1 1 auto;
            }
        }
    }
}
.faq {
    .acc-tab {
        background-color: #D9EBF5;
        margin-bottom: 1.5em;
        width: 48%;
        flex: 0 0 48%;
        cursor: pointer;
        padding-top: 2rem;
        padding-bottom: 1rem;
        position: relative;
        @extend %block-shadow-2;
        @include bdown(md){
            flex: 0 0 100%;
        }
        &:before {
            content: "";
            width: 30%;
            left: 28px;
            height: 2px;
            background-color: $blue;
            display: block;
            top: 1rem;
            position: absolute;
        }
        &:nth-child(odd){
            margin-right: 4%;
        }
        .acc-title {
            color: $dark-blue;
            @include fluid-font(28px);
            font-family: $raj;
            font-weight: 600;
            @include transition;
            padding-left: 28px;
            padding-right: 6rem;
            min-height: 4.84rem;
            display: flex;
            align-items: center;
            // position: relative;
            &:before {
                content: "+";
                color: $dark-blue;
                position: absolute;
                right: 0;
                top: 0;
                line-height: 1;
                width: 2.53rem;
                height: 2.53rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 62px;
                z-index: 10;
                @include transition;
                transform-origin: center;
            }
            &:after {
                @include transition;
                content: "";
                display: flex;
                position: absolute;
                width: 2.53rem;
                height: 2.53rem;
                color: #fff;
                background-color: #9FD8F8;
                right: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                // height: 100%;
                cursor: pointer;
                @include bdown(xs) {
                    font-size: 40px;
                    width: 50px;
                }
            }
            &:hover:after {
                background-color: $dark-blue;
            }
            &:hover:before {
                color: #fff;
            }
            &.accordion-active {
                color: $blue;
                &:after {
                    background-color: $dark-blue;
                    max-height: 4.84rem;
                }
                &:before {
                    transform: rotate(45deg);
                    color: #fff;
                }
            }
        }
        .acc-text {
            color: #000;
            padding: 1em 4.84rem 1em 28px;
            @include bup(md){
                line-height: 29px;
            }
            @include font-count(14, 16);
        }
        &.tab-active {
            background-color: #fff;
        }
    }
    .panel {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        &:after {
            width: 100%;
            content: "";
            clear: both;
            display: block;
        }
    }
    h2 {
        @include fluid-font(36px);
    }
}

.contacts-aside {
    padding-left: 2rem;
    margin-top: 1rem;
    position: relative;
    &:before {
        position: absolute;
        content: "";
        display: block;
        left: 0;
        top: 0;
        height: 100%;
        width: 2rem;
        background-image: url(../../images/icons/t-contact.svg);
        background-repeat: no-repeat;
        background-position: right top;
    }
}
.contact-info {
    color: #353939;
    padding-top: 1rem;
    padding-left: 1rem;
    li {
        position: relative;
        padding-left: 2rem;
        margin-bottom: 2rem;
        @include fluid-font(22px);
        font-weight: 600;
        &:before {
            position: absolute;
            left: 0;
            top: 0;
            font-size: 1.2rem;
            font-weight: 400;
            color: $blue;
        }
    }
    a {
        &:hover {
            color: $dark-blue;
        }
    }
}
.contacts-follow-us {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    span {
        @include fluid-font(22px);
        font-weight: 600;
        color: #353939;
    }
    &--list {
        @extend %share-style3;
        list-style: none;
        margin-left: 1rem;
        li {
            list-style-type: none !important;
        }
    }
}
.album h2 {
    @include fluid-font(36px);
    font-weight: 500;
}



//  .icon-links {
//     display: flex;
//     justify-content: space-between;
//     padding: 2rem 0 1rem; 
//     flex-wrap: wrap;
//     li {
//         flex: 0 0 14%;
//         margin-bottom: 3rem;
//         display: flex;
//         justify-content: space-between;
//     }
//     .icon-link {
//         width: 100%;
//         height: 0;
//         padding-top: 100%;
//         position: relative;
//         display: inline-flex;
//         flex-direction: column;
//         font-weight: 700;
//         &:hover {
//             .img-container {
//                 background-color: $blue !important;
//             }
//         }
//         .img-container {
//             // width: 10.8rem;
//             // height: 10.8rem;
//             @include transition;
//             position: absolute;
//             left: 0;
//             top: 0;
//             height: 100%;
//             width: 100%;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             margin-left: auto;
//             margin-right: auto;
//             img {
//                 padding: 1rem;
//             }
//         }
//         span {
//             position: absolute;
//             left: 0;
//             width: 100%;
//             padding-top: 1rem;
//             display: block;
//             text-align: center;
//             font-weight: 700;
//             @include fluid-font(17px);
//             text-transform: uppercase;
//             @include letter-spacing(100);
//         }
//     }
//     @include bup(nm){
//         li {
//             margin: 0 5% 7%;
//         }
//     }
//     @include bdown(md){
//         // flex-wrap: wrap;
//         li {
//             flex: 0 0 49%;
//             justify-content: center;
//             .icon-link {
//                 width: 75%;
//                 padding-top: 75%;
//             }
//         }
//     }
//     @include bdown(xs){
//         // flex-wrap: wrap;
//         li {
//             flex: 0 0 49%;
//             justify-content: center;
//             .icon-link {
//                 width: 95%;
//                 padding-top: 95%;
//             }
//         }
//     }
//     @include bdown(xxs){
//         li {
//             flex: 0 0 100%;
//         }
//     }
//     &--offset {
//         li {
//             &.standout {
//                 .icon-link {
//                     .img-container {
//                         flex-direction: column;
//                     }
//                     img {
//                         padding: 0;
//                     }
//                     span {
//                         position: static;
//                         color: #fff;
//                         @include fluid-font(31px);
//                         font-weight: 700;
//                     }
//                 }
//             }
//             @include bup(md){
//                 margin: 0 2% 2%;
//                 &.standout {
//                     flex: 0 0 20%;
//                     margin: 0 5% 2% 15%;
//                 }
//             }
//         }
//     }
// }
// .apply {
//     color: #fff;
//     @include fluid-font(25px);
//     font-weight: 600;
//     .col:first-child {
//         @include letter-spacing(100);
//     }
//     .btn {
//         &:hover {
//             background-color: $light-blue;
//         }
//     }
//     @include bup(md){
//         padding-top: 20px;
//         padding-bottom: 20px;
//     }
//     .small-text {
//         @include fluid-font(21px);
//         @include letter-spacing(100);
//     }
// }




.map-container {
    iframe {
        width: 100%;
        height: 517px;
    }
}

section.fullwidth-banner {
    .banner-layout {
        background-size: auto 100%;
        background-repeat: no-repeat;
        position: relative;
        @extend %title-block;
        .container {
            z-index: 5;
            position: relative;
        }
        &.position-right {
            background-position: left top;
            &:after {
                position: absolute;
                content: "";
                top: 0;
                height: 100%;
                left: 0;
                width: 100%;
                background-image: linear-gradient(to left, #000, #000 35%, transparent 70%, transparent);
            }
            .container {
                text-align: right;
            }
        }
        &.position-left {
            background-position: right top;
            &:after {
                position: absolute;
                content: "";
                top: 0;
                height: 100%;
                left: 0;
                width: 100%;
                background-image: linear-gradient(to right, #000, #000 35%, transparent 70%, transparent);
            }
            .container {
                text-align: left;
            }
        }
        .btn-container {
            margin-top: 2rem;
        }
        .banner {
            &--info {
                text-align: left;
                display: inline-block;
                padding-left: 2em;
                padding-right: 2em;
                color: #fff;
            }
            &--inner {
                padding: 3rem;
                margin-bottom: 1rem;
                line-height: 1;
                display: inline-block;
            }
        }
        & + .banner-layout {
            margin-top: 1rem;
        }
        @include bup(md){
            .banner--info {
                max-width: 815px;
                padding-top: 3em;
                padding-bottom: 3em;
            }
        }
        @include bdown(md){
            .banner--info {
                max-width: 50%;
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }
        @include bdown(sm){
            .banner--info {
                max-width: none !important;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    &.coach-list {
        .banner-layout {
            .title-2 {
                color: $dark;
            }
        }
    }
}

.featured-media-container.video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.media-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.img-cover {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}


