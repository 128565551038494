$base-font-size: 19px;
$images: '../../images';
$icons: '#{$images}/icons';

$base: #444444;
$gray: #D8D8D8;
$dark: #222222;
$green: #82C341;
$dark-blue: #0D2B70;
$blue: #117FB9;
$light-blue: #9FD8F8;
$orange: #FF7702;

// $d-blue: #3363A8;
// $light-blue: rgba(190, 228, 237, .41);
// $brown: #AE8F62;

//breakpoints
$breakpoints: (
    'hd': 1919px,
    'lg': 1400px,
    'nm': 1240px,
    'md': 991px,
    'sm': 768px,
    'xs': 480px,
    'xxs': 385px
)!default;