@import "typography";
html {
    font-size: 19px;
    font-family: $cairo;
    @include bdown(md){
        font-size: 14px;
    }
}
a[href],
button {
    &:not(.disabled):not(:disabled) {
        cursor: pointer;
    }
    &.disabled,
    &:disabled {
        cursor: default;
        pointer-events: none;
    }
    transition: all .3s ease-in-out;
}
body {
    line-height: 1.25;
    font-size: 1rem;
    color: $base;
    background-color: #E3F5FF;
}

.gray {
    color: $gray;
}
.blue {
    color: $blue;
}
.dark-blue {
    color: $dark-blue;
}
.white {
    color: #fff;
}
.green {
    color: $green;
}
.blue-bg {
    background-color: $blue;
}
.light-blue-bg {
    background-color: $light-blue;
}
.lightest-blue-bg {
    background-color: rgba($color: $blue, $alpha: .06);
}
.light-green-bg {
    background-color: rgba($color: $green, $alpha: .14);
}
.lightest-green-bg {
    background-color: rgba($color: $green, $alpha: .06);
}
.dark-blue-bg {
    background-color: #1D235B;
}
.beige-bg {
    background-color: #f7f7f7;
}
.brown-bg {
    background-color: $green;
}
.dark-bg {
    background-color: #444;
}
.no-bg {
    background-image: none !important;
}
.underline {
    text-decoration: underline;
}
.all-caps {
    text-transform: uppercase;
}
.pr {
    position: relative;
}
.bold {
    font-weight: 700;
}
.no-pt {
    padding-top: 0;
}
.no-pb {
    padding-bottom: 0;
}
.no-p {
    padding-top: 0;
    padding-bottom: 0;
}
.block-shadow {
    @extend %block-shadow-2;
    &--top{
        @extend %block-shadow-3;
    }
}
.logo {
    &.blured {
        position: relative;
        z-index: 5;
        &:after {
            content: url(../../images/blurb.svg);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            filter: blur(42px);
            z-index: -1;
            pointer-events: none;
        }
    }
}
h2 {
    .blured {
        position: relative;
        &:after {
            content: url(../../images/blurb2.svg);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            // filter: blur(42px);
            z-index: -1;
        }
    }
}
.repeat-bg {
    background-image: url(../../images/repeat-grid.jpg);
    background-repeat: repeat;
    &.blured {
        &:before {
            z-index: 0;
        }
    }
}
section.blured {
    position: relative;
    &:before {
        position: absolute;
        content: "";
        background-image: url(../../images/blurb2.svg);
        background-position: center -70px;
        background-repeat: no-repeat;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        display: block;
        z-index: 0;
    }
}

h2, .h2-style {
    @include fluid-font(55px);
    font-weight: 500;
    line-height: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include letter-spacing(35);
    &:before,
    &:after {
        display: inline-flex;
        padding-bottom: .1em;
    }
    &:before {
        content: url(#{$images}/icons/t-right.svg);
        padding-right: .3em;
    }
    &:after {
        content: url(#{$images}/icons/t-left.svg);
        padding-left: .3em;
    }
    @include bdown(md){
        overflow: hidden;
    }
}
h1, h2, h3, h4, h5, h6 {
    margin-bottom: .4em;
    // @include letter-spacing(55);
    font-family: $raj;
    color: $dark;
}
h3 {
    @include fluid-font(31px);
    font-weight: 600;
    color: $dark-blue;
}
p {
    margin-bottom: 1em;
}
h4 {
    @include fluid-font(25px);
}
h5 {
    @include fluid-font(20px);
}
h6 {
    @include fluid-font(19px);
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
@include bup(md){
    .responsive {
        &-text-right {
            text-align: right;
        }
        &-text-center {
            text-align: left;
        }
    }
}

@include bup(md){
    .r-text-left {
        text-align: left;
    }
    .r-text-center {
        text-align: center;
    }
    .r-text-right {
        text-align: right;
    }
}
.flex {
    &--am {
        align-items: center;
    }
    &--auto {
        flex-basis: auto !important;
    }
}
p {
    line-height: 26px;
    &.subtitle {
        margin-bottom: .5em;
        text-align: center;
        @include letter-spacing(200);
        @include fluid-font(26px);
        color: $blue;
    }
    @include bdown(md) {
        line-height: 1.5;
    }
    // &.intro {
    //     margin-bottom: .5em;
    //     @include font-count(17, 22);
    //     @include letter-spacing(30);
    // }
}
.pretitle {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    @include fluid-font(24px);
    @include letter-spacing(100);
    color: #0D2B70;
}
.decorated {
    position: relative;
    &:before {
        content: url(#{$images}/icons/decoration.svg);
        display: block;
    }
}
.double-arrow {
    display: inline-flex;
    align-items: center;
    color: $blue;
    font-size: 15px;
    font-weight: 600;
    @include letter-spacing(50);

    &:hover{
        color: $light-blue;
    }
    &:after {
        content: "\e90a";
        padding-left: .5rem;
        font-family: $icomoon;
        display: inline-block;
    }
}


a[name^="eztoc"] {
    display: none !important;
}
// .text-center {
//     ul {
//         text-align: left;
//     }
// }
.btn {
    color: #fff;
    background-color: transparent;
    @include fluid-font(18px);
    @include transition;
    @include letter-spacing(120);
    font-weight: 500;
    line-height: normal;
    border: none;
    padding: 1em 2.5em;
    display: inline-flex;
    align-items: center;
    font-family: $raj;
    border: 2px solid $blue;
    &--blue {
        color: $dark-blue;
        background-color: #fff;
        &:hover {
            color: #fff;
        }
    }
    &--small {
        padding: .5em 1em;
    }
    &--block {
        display: block;
        text-align: center;
    }
    &--arrowed {
        position: relative;
        &:after {
            content: url(../../images/icons/t-button.svg);
            display: block;
            margin-left: .5rem;
        }
    }
    &--maintenance {
        align-items: center;
        font-weight: 600;
        padding: .5em 1em;
        @include fluid-font(29px);
        @include letter-spacing(44);
        background-color: transparent;
        img {
            margin-right: 1.5rem;
        }
    }
    &--icon-right {
        padding-right: 4em;
        position: relative;
        &:before {
            position: absolute;
            display: block;
            right: 2em;
            top: 50%;
            transform: translateY(-50%);
            font-family: $icomoon;
        }
    }
    &:hover {
        background-color: $blue;
    }
    
    // font-size: 18px;
}
.flex {
    &--aic {
        align-items: center;
    }
}


section {
    ul:not([class]) {
        margin-bottom: 20px;
        margin-left: 0;
        display: inline-flex;
        flex-direction: column;
        li {
            list-style-type: none;
            position: relative;
            padding-left: 1.5em;
            &:before {
                content: "\e90c";
                font-family: $icomoon;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                color: $green;
                display: inline-block;
            }
            @include bup(md){
                line-height: 26px;
            }
        }
    }
    ol:not([class]) {
        margin-bottom: 20px;
        margin-left: 15px;
        display: inline-flex;
        flex-direction: column;
    }
    .text-block.no-dot {
        ul {
            li {
                &:before {
                    content: none;
                }
            }
        }
    }
}
section a:not([class]) {
    color: $blue;
    &:hover {
        color: $dark-blue;
    }
}

