@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?aqnyq7');
  src:  url('../fonts/icomoon.eot?aqnyq7#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?aqnyq7') format('truetype'),
    url('../fonts/icomoon.woff?aqnyq7') format('woff'),
    url('../fonts/icomoon.svg?aqnyq7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  &:before {
    font-family: 'icomoon' !important;
  }
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-member:before {
  content: "\e900";
}
.icon-person:before {
  content: "\e901";
}
.icon-globe:before {
  content: "\e902";
}
.icon-quote1:before {
  content: "\e903";
}
.icon-clock:before {
  content: "\e911";
}
.icon-back-arrow:before {
  content: "\e912";
}
.icon-computer:before {
  content: "\e913";
}
.icon-mobile:before {
  content: "\e904";
}
.icon-phone:before {
  content: "\e918";
}
.icon-pin:before {
  content: "\e905";
}
.icon-envelope-o:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e92c";
}
.icon-magnifier:before {
  content: "\e92c";
}
.icon-magnifying-glass:before {
  content: "\e92c";
}
.icon-inspect:before {
  content: "\e92c";
}
.icon-find:before {
  content: "\e92c";
}
.icon-calendar:before {
  content: "\e907";
}
.icon-pin1:before {
  content: "\e914";
}
.icon-rectangular-points:before {
  content: "\e915";
}
.icon-s-arr-left:before {
  content: "\e916";
}
.icon-s-arr-right:before {
  content: "\e917";
}
.icon-chevron-right:before {
  content: "\e919";
}
.icon-chevron-left:before {
  content: "\e91a";
}
.icon-angle-right:before {
  content: "\e91b";
}
.icon-angle-left:before {
  content: "\e91c";
}
.icon-angle-down:before {
  content: "\e920";
}
.icon-angle-up:before {
  content: "\e921";
}
.icon-angle-double-down:before {
  content: "\e908";
}
.icon-angle-double-up:before {
  content: "\e909";
}
.icon-angle-double-right:before {
  content: "\e90a";
}
.icon-angle-double-left:before {
  content: "\e90b";
}
.icon-clock-o:before {
  content: "\e91d";
}
.icon-arrow-circle-right:before {
  content: "\e91e";
}
.icon-arrow-circle-left:before {
  content: "\e91f";
}
.icon-check:before {
  content: "\e90c";
}
.icon-close:before {
  content: "\e922";
}
.icon-bars:before {
  content: "\e923";
}
.icon-pdf:before {
  content: "\e924";
}
.icon-facebook:before {
  content: "\e925";
}
.icon-facebook-transparent:before {
  content: "\e928";
}
.icon-instagram-transparent:before {
  content: "\e929";
}
.icon-twitter:before {
  content: "\e926";
}
.icon-linkedin-square:before {
  content: "\e90d";
}
.icon-skype:before {
  content: "\e90e";
}
.icon-youtube-play:before {
  content: "\e90f";
}
.icon-pinterest:before {
  content: "\e910";
}
.icon-instagram:before {
  content: "\e927";
}
.icon-twitter-transparent:before {
  content: "\e92b";
}
.icon-whatsup:before {
  content: "\e92a";
}
.icon-pencil:before {
  content: "\e92d";
}
.icon-bank:before {
  content: "\e92e";
}
.icon-calendar-2:before {
  content: "\e92f";
}
.icon-chart-2:before {
  content: "\e930";
}
.icon-chart-grow:before {
  content: "\e931";
}
.icon-chart:before {
  content: "\e932";
}
.icon-download:before {
  content: "\e933";
}
.icon-enter:before {
  content: "\e934";
}
.icon-money:before {
  content: "\e935";
}
.icon-rates:before {
  content: "\e936";
}
.icon-two-hands:before {
  content: "\e937";
}
.icon-wire:before {
  content: "\e938";
}
.icon-youtube:before {
  content: "\e939";
}
.icon-linkedin:before {
  content: "\e93a";
}
.icon-check-circle:before {
  content: "\e93b";
}
.icon-quote:before {
  content: "\e93c";
}