header {
    transition: background-color .4s;
    .container {
        max-width: 1500px;
        position: relative;
    }
    .header-top {
        padding-top: 5px;
        padding-bottom: 8px;
        position: relative;
        overflow: hidden;
        .row {
            align-items: center;
            position: relative;
        }
        .col {
            position: relative;
            z-index: 2;
            &:nth-child(1){
                flex: 0 1 25%;
                z-index: 1;
            }
            &:nth-child(2){
                display: flex;
                flex: 1 1 auto;
                align-items: center;
                justify-content: space-around;
            }
            &:nth-child(3){
                text-align: right;
                position: static;
            }
        }
        .logo {
            display: block;
        }
        .header--info-container {
            display: flex;
            align-items: center;
            &-left {
                font-family: $raj;
                @include fluid-font(17px);
                font-weight: 700;
                max-width: 260px;
            }
            &-right {
                display: flex;
                align-items: center;
                img {
                    margin-right: 1rem;
                }
                span {
                    max-width: 140px;
                    font-family: $raj;
                    @include fluid-font(22px);
                    font-weight: 500;
                }
            }
        }
        .top-links {
            a {
                background-color: #444;
                padding: .5rem 1rem .5rem .5rem;
                color: $blue;
                align-items: center;
                display: inline-flex;
                white-space: nowrap;
                border: 1px solid #707070;
                &:hover {
                    background-color: $dark-blue;
                }
                img {
                    margin-right: .5rem;
                }
                span {
                    @include fluid-font(30px);
                    font-family: $raj;
                    font-weight: 700;
                    color: #fff;
                }
            }
        }
        .btn {
            background-color: $light-blue;
            letter-spacing: normal;
            display: inline-flex;
            align-items: center;
            font-family: $raj;
            @include fluid-font(19px);
            font-weight: 400;
            justify-content: center;
            border-radius: 0;
            &:before {
                margin-right: .5rem;
                color: $green;
            }
            &:hover {
                background-color: $green;
                &:before {
                    color: $blue;
                }
            }
        }

        @include bup(md) {
            padding-top: 11px;
            &:before {
                position: absolute;
                z-index: 2;
                content: "";
                display: block;
                height: 11px;
                top: 0;
                left: 0;
                width: 100%;
                background-color: $blue;
            }
            .top-links {
                position: absolute;
                top: -10px;
                right: 0;
                z-index: 30;
            }
            .top-phone-block {
                margin-left: 5em;
            }
        }
        @include bdown(md) {
            padding-top: 0;
            padding-bottom: 0;
            .container {
                justify-content: center;
            }
            .col {
                margin-bottom: 10px;
            }
            .col:nth-child(2) {
                justify-content: space-between;
            }
            .col:nth-child(3) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 0;
            }
            .header--info-container{
                &-right {
                    span {
                        display: none;
                    }
                    img {
                        margin-right: 0;
                    }
                }
            }
            .top-phone-block {
                display: block;
                padding-left: 0;
                span {
                    display: none;
                }
                a {
                    display: inline-block;
                }
                // & > * {
                //     display: block;
                //     margin-left: auto;
                //     margin-right: auto;
                // }
            } 
        }
    }
    .header-bottom {
        background-color: #313131;
        position: relative;
        z-index: 10;
        // .container {
        //     display: flex;
        //     align-content: center;
        // }
        // .nav-container {
        //     display: flex;
        //     justify-content: space-between;
        //     flex: 1 0 auto;
        // }
        nav {
            // width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            a {
                position: relative;
                text-transform: uppercase;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 auto;
                @include transition;
                line-height: 26px;
                background-color: transparent;
                padding: .4em .7em;
                font-family: $raj;
                @include fluid-font(30px);
                font-weight: 600;
                &.nav-item-active {
                    color: $light-blue;
                }
                &:hover {
                    color: $light-blue;
                }
            }
        }
        @include bup(md){
            nav {
                a {
                    padding: .46em .5em .66em .5em;
                    flex-grow: 1;
                    &.nav-item-active {
                        // border-bottom: 6px solid $green;
                        position: relative;
                        &:after {
                            position: absolute;
                            display: block;
                            content: url(../../images/icons/t-menu.svg);
                            bottom: -4px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                    &:before {
                        position: absolute;
                        content: "";
                        display: block;
                        right: 0;
                        width: 2px;
                        height: 40%;
                        top: 45%;
                        transform: translateY(-50%);
                        background-color: $light-blue;
                    }
                    &:last-of-type {
                        &:before {
                            content: none;
                        }
                    }
                }
            }
        }
        @include bup(lg){
            @include font-count(10, 22);
        }
        @include bdown(lg) {
            @include fluid-font(14px);
        }
        @include bdown(md) {
            font-size: 16px;
            .container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                position: static;
                padding-left: 0;
                padding-right: 0;
            }
            .nav-container {
                display: none;
            }
            nav {
                flex-direction: column;
                padding-left: 0;
                padding-right: 0;
                width: 100%;
                // height: 100vh;
                justify-content: space-evenly;
                background-color: $blue;
                // background-image: linear-gradient(to right, $light-blue, $blue 80%, $blue);
                // background-image: linear-gradient(to right, rgba(0, 140, 153, .95), rgba(62, 76, 202, .95));
                a {
                    display: block;
                    font-size: 16px;
                    padding: .5em 5%;
                    &.nav-item-active, &:hover {
                        background-color: $light-blue;
                        color: #fff;
                        &:after {
                            content: none;
                        }
                    }
                    &:last-of-type {
                        margin-left: 0;
                    }
                    &:before {
                        content: none;
                    }
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
    span.nav-toggle {
        text-align: center;
        font-family: 'icomoon';
        font-size: 2em;
        min-width: 25px;
        display: block;
        transition: transform .3s ease;
        transform-origin: 50% 50%;
        color: $dark-blue;
        &:before {
            content: "\e923";
        }
        &.close {
            transform: rotate(-180deg);
        }
        &.close:before {
            content: "\e922";
        }
    }
}
