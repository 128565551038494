%title-block {
    .title-1 {
        color: #fff;
        @include fluid-font(35px);
        display: flex;
        margin-bottom: .5rem;
    }
    .title-2 {
        @include fluid-font(48px);
        line-height: 1;
        color: $green;
    }
    .title-3 {
        @include font-count(40, 77);
        display: inline-flex;
        align-items: center;
        color: $light-blue;
        margin-bottom: .3em;
        .sm {
            font-size: 16px;
        }
    }
    p {
        color: #fff;
        @include fluid-font(20px);
    }
}
%slider-arrow-style-1 {
    .slick-arrow {
        position: absolute;
        color: $gray;
        z-index: 100;
        top: 50%;
        transform: translateY(-50%);
        font-family: $icomoon;
        @include fluid-font(50px);
        @include transition;
        background-color: rgba($color: #444444, $alpha: .73);
        &:hover {
            background-color: rgba($color: #444444, $alpha: .9);
        }
    }
    .slick-prev {
        left: 0;
        padding: 1px .33em 1px 0.25em;
    }
    .slick-next {
        right: 0;
        padding: 1px .25em 1px 0.33em;
    }
}
%share-style1 {
    display: flex;
    padding-top: 0;
    padding-right: 0;
    align-items: center;
    a {
        font-size: 1.3em;
        height: 1.3em;
        width: 1.3em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1.2rem;
        color: $blue;
        &:hover {
            color: $dark-blue;
        }
    }
}
%share-style2 {
    .icon {
        width: 2rem;
        height: 2rem;
        font-size: 1.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        background-color: rgba($color: #ffffff, $alpha: .1);
        border-radius: 50%;
        &:before {
            font-size: .7em;
        }
        &:hover {
            color: #fff;
        }
        &-facebook {
            background-color: #3B5998;
        }
        &-pinterest {
            background-color: #CB2027;
        }
        &-twitter {
            background-color: #7ED0E2;
        }
        &-linkedin-square {
            background-color: #486AAE;
        }
        &-instagram {
            background-color: #506897;
        }
    }
}
%share-style3 {
    display: flex;
    padding-top: 0;
    padding-right: 0;
    align-items: center;
    a {
        font-size: 1.2em;
        height: 2rem;
        width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $blue;
        border-radius: 50%;
        color: $blue;
        margin: 0 3px;
        &:hover {
            color: $dark-blue;
        }
    }
}

%letter-form {
    .decorated {
        position: relative;
        &:after {
            width: 174px;
        }
    }
    h3 {
        @include letter-spacing(30);
        @include fluid-font(45px);
    }
    input, select, textarea {
        background-color: transparent;
        border: none;
        @include fluid-font(16px);
        width: 100%;
    }
    select, input {
        border: 1px solid #5F473F;
        line-height: 2rem;
    }
    input {
        padding: 0;
    }
    select {
        -webkit-appearance: none;
        border-bottom-color: transparent;
    }
    textarea {
        // background-image: linear-gradient(180deg,hsla(0,0%,96.9%,0),hsla(0,0%,96.9%,0) calc(100% - 1px),#5F473F calc(100% - 1px), #5F473F);
        // background-size: 100% 3em;
        line-height: 2.5rem;
    }
    .select-wrapper {
        position: relative;
        border-bottom: 1px solid #5F473F;
        &:before {
            position: absolute;
            right: .2em;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            pointer-events: none;
        }
    }
}

%ulticks {
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1em;
        &:before {
            content: "\e90c";
            font-family: $icomoon;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $blue;
            display: inline-block;
        }
    }
}
%ularrows {
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1em;
        &:before {
            content: "\e91b";
            font-family: $icomoon;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
        }
    }
}
%phone-block {
    color: $orange;
    span {
        @include fluid-font(25px);
        font-weight: 600;
    }
    // font-weight: 900;
    &:before {
        margin-right: .5rem;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        @include transition;
        background-color: $light-blue;
        color: #FAF6EC;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    &:hover {
        color: $blue;
        &:before {
            background-color: $blue;
        }
    }
}
%default-form {
    position: relative;
    input, select, textarea {
        @include font-count(16, 22);
        color: #353939;
    }
    .btn-container {
        .btn {
            padding: 1em 3em;
        }
    }
    .row {
        padding-top: 0px;
        padding-bottom: 0px;
        .col {
            margin-bottom: 1em;
            input, textarea {
                width: 100%;
            }
            input {
                height: 3.1rem;
                padding: 0 1.5em;
            }
            .select-wrapper {
                height: 3.1rem;
                position: relative;
                @include font-count(16, 24);
                &:before {
                    position: absolute;
                    right: .2em;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 3em;
                    z-index: 10;
                    pointer-events: none;
                }
                &:after {
                    position: absolute;
                    display: block;
                    content: "";
                    right: 0;
                    top: 0;
                    width: 1.6em;
                    height: 100%;
                    background-color: #89D0EA;
                    font-size: 2em;
                    z-index: 5;
                    pointer-events: none;
                }
            }
            select {
                height: 3.1rem;
                padding: 0 1.5em;
                width: 100%;
                border-radius: 0;
                border: none;
                -webkit-appearance: none;
            }
            textarea {
                padding: 1.5em;
                // margin-bottom: 1em;
            }
            &.fg-2 {
                flex-grow: 2;
            }
            &.fg-3 {
                flex-grow: 3;
            }
            &.fg-4 {
                flex-grow: 4;
            }
            &.file-upload-container {
                text-align: right;
                &.has-danger {
                    border: 1px solid #f00;
                    mark {
                        color: #f00;
                    }
                }
            }
            label {
                text-align: left;
                color: $blue;
                @include fluid-font(23px);
                display: block;
                padding-bottom: .2em;
                &.file_upload {
                    display: inline-flex;
                    align-items: flex-start;
                    input[type=file]{
                        position: absolute;
                        visibility: hidden;
                        opacity: 0;
                        display: none;
                    }
                    mark {
                        background-color: transparent;
                        color: #fff;
                        opacity: 1;
                        margin-left: .5em;
                        @include transition;
                    }
                    .btn {
                        cursor: pointer;
                        &.empty {
                            background-color: transparent;
                            color: #CBCBCB;
                            border: 1px solid #CBCBCB;
                        }
                        &:not(.empty):hover {
                            & + mark {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
            @include bdown(md) {
                margin-bottom: 15px;
            }
            p {
                text-align: left;
                margin-bottom: 0.5em;
                &.question {
                    @include font-count(24, 33);
                }
                &.c-info {
                    @include font-count(16, 24);
                    @include letter-spacing(100);
                }
            }
        }
    }
    .total-container {
        border-top: 1px solid $blue;
        color: $blue;
        text-align: left;
        padding-top: .5em;
        margin-top: 2em;
        margin-bottom: 2em;
        span {
            color: inherit;
            @include font-count(20, 33);
        }
        .total {
            text-transform: uppercase;
        }
    }
    .checkbox input {
        display: none;
    }
    .checkbox label {
        position: relative;
        padding-left: 4em;
        line-height: 3em;
        cursor: pointer;
        &:before {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 3em;
            height: 3em;
            background-color: #fff;
        }
        &:after {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            padding-left: .4em;
            content: "";
            @include font-count(30, 48);
            color: $blue;
        }
    }
    
    .checkbox input:checked + label {
        &:after {
            content: "X";
        }
    }
    .captcha-inside {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        input {
            flex-basis: 50%;
        }
        @include bdown(md) {
            justify-content: flex-start;
            input {
                flex-basis: auto;
            }
        }
    }
}
%block-shadow-1 {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../images/block-shadow.png);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -2rem;
        height: 2rem;
        pointer-events: none;
    }
}
%block-shadow-2 {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../images/block-shadow.png);
        background-size: 100% 3.2rem;
        background-repeat: no-repeat;
        background-position: center top;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -3.2rem;
        height: 3.2rem;
        pointer-events: none;
    }
}
%block-shadow-3 {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../images/block-shadow.png);
        background-size: 100% 3.2rem;
        background-repeat: no-repeat;
        background-position: center top;
        position: absolute;
        width: 100%;
        left: 0;
        top: 0rem;
        height: 3.2rem;
        pointer-events: none;
    }
}
%double-shadow {
    position: relative;
    &:after {
        content: "";
        background-image: url(../../images/double-shadow.png);
        background-size: 100% 3.2rem;
        background-repeat: no-repeat;
        background-position: center top;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1.4rem;
        height: 3.2rem;
        z-index: -5;
    }
}


