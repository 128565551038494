section.cf {
    position: relative;
    background-position: right 2rem;
    background-repeat: no-repeat;
    &--right {
        background-position: left 2rem;
        form {
            margin-left: auto;
        }
    }
    &:after {
        content: "";
        background-image: linear-gradient(rgba($color: #F6F7F5, $alpha: 1 ), rgba($color: #EDEFEC, $alpha: 1 ), rgba($color: #DFE1DE, $alpha: 0 ));
        position: absolute;
        left: 0;
        top: 0;
        height: 30%;
        width: 100%;
    }
    &.clear {
        background-position: right top;
        &:after {
            content: none;
        }
    }
    form {
        position: relative;
        z-index: 5;
    }
}

form {
    input, select, textarea {
        &.has-danger {
            border: 2px solid #f00 !important;
        }
    }
    #thankyou-url {
        display: none;
    }
    .cap-img {
        display: flex;
        flex-grow: 2;
        justify-content: flex-end;
        align-items: center;
        span {
            color: $dark-blue;
            @include fluid-font(20px);
        }
        @include bdown(md){
            justify-content: flex-start;
        }
    }
}

form.hsf {
    @extend %letter-form;
    
    background-color: rgba($color: $light-blue, $alpha: .57);
    border: .5625rem solid #fff;
    color: #4a4a4a;
    text-align: center;
    ::placeholder {
        color: #4a4a4a;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        transition: background-color 5000s ease-in-out 0s;
        // -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    // input::-internal-autofill-selected {
    //     background-color: transparent !important;
    // }


    input, select, textarea {
        color: #4a4a4a;
    }

    .col {
        margin-bottom: 1rem;
    }

    .btn {
        border-radius: 0;
        border: none;
        @include fluid-font(18px);
        font-weight: 400;
    }
    @include bup(md) {
        padding: 2rem;
        max-width: 55%;
    }
    @include bdown(md) {
        padding: 1rem;
    }
}
form#question-form{
    @extend %letter-form;
    .row {
        margin-bottom: 1rem;
    }
    .col {
        align-items: center;
        &.vcenter {
            display: inline-flex;
            margin-bottom: 5px;
        }
    }
    label {
        @include fluid-font(18px);
        font-weight: 700;
        @include letter-spacing(100);
    }
    input, select, textarea {
        background-color: #fff;
        padding: 0 1rem;
        font-weight: 700;
        @include fluid-font(18px);
        border-color: transparent;
    }
    input {
        line-height: 45px;
    }
}
form.default-form {
    @extend %default-form;
    padding: 1rem 2rem 2rem;
    position: relative;
    input, select, textarea {
        border: 2px solid $blue;
    }
    h3 {
        @include fluid-font(27px);
    }
    .btn {
        color: $dark-blue;
        font-weight: 600;
        @include fluid-font(20px);
        @include letter-spacing(50);
        height: 3.1rem;
        padding: 0 2rem;
        &:hover {
            background-color: #fff;
        }

    }
}

.pencil {
    .col {
        position: relative;
        &:after {
            position: absolute;
            top: 1.75rem;
            transform: translateY(-50%);
            content: "\e92d";
            right: 2rem;
            font-family: $icomoon;
            color: $light-blue;
        }
    }

}
