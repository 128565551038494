footer {
    color: #fff;
    a {
        color: #e7e7e7;
        &:hover, &.active {
            color: $light-blue;
        }
    }
    .footer-top {
        background-color: $dark-blue;
        border-top: .68rem solid $blue;
        .container {
            padding-top: 5rem;
            padding-bottom: 2.2rem;
            position: relative;
            .footer-phone {
                position: absolute;
                @include fluid-font(34px);
                font-weight: 700;
                color: $light-blue;
                display: flex;
                align-items: center;
                top: -3.5rem;
                right: 0;
                width: 37%;
                span {
                    padding-top: 2rem;
                }
            }
            .footer-social-links {
                position: absolute;
                display: inline-flex;
                top: 0;
                left: 0;
                li:nth-child(odd){
                    a {
                        background-color: #222222;
                    }
                }
                li:nth-child(even){
                    a {
                        background-color: #313131;
                    }
                }
                li a {
                    width: 2.73rem;
                    height: 2.73rem;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    @include fluid-font(30px);
                    color: #fff;
                    &:hover {
                        background-color: #05245D;
                    }
                }
            }
            
            .row {
                flex-wrap: wrap;
                .col {
                    // border-left: 3px solid rgba($color: $light-blue, $alpha: .19);
                    &:nth-child(1){
                        flex: 0 0 20%;
                        border-left: none;
                    }
                    &:nth-child(2) {
                        flex: 0 0 15%;
                    }
                    &:nth-child(3) {
                        flex: 0 0 25%;
                    }
                    &:nth-child(4) {
                        flex: 0 0 40%;
                        padding-left: 5%;
                    }
                    .footer-contacts {
                        @include rlh(19px);
                        .icon {
                            position: relative;
                            padding-left: 2rem;
                            display: inline-block;
                            line-height: inherit;
                            font-family: $raj;
                            &:before {
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                color: $blue;
                            }
                        }
                    }
                    .footer-quick-links {
                        text-transform: uppercase;
                        font-weight: 500;
                        @include fluid-font(30px);
                        display: inline-block;
                        text-align: left;
                        font-family: $raj;
                        li {
                            margin-bottom: .3em;
                        }
                        &.alt {
                            text-transform: none;
                            font-family: $cairo;
                            @include fluid-font(22px);
                        }
                    }
                }
            }

            .footer-info {
                display: flex;
                align-items: center;
                @include fluid-font(20px);
                color: $light-blue;
                img {
                    margin-right: 1.5rem;

                }
                span {
                    max-width: 140px;
                    display: block;
                }
            }
        }
    }
    .footer-bottom {
        background-color: #313131;
        border-top: 1px solid #707070;
        color: #fff;
        padding-top: 1rem;
        padding-bottom: 1rem;
        @include fluid-font(15px);
    }
    @include bup(md){
        .footer-top {
            .col {
                border-left: 3px solid rgba($color: $light-blue, $alpha: .19);
                &:nth-child(2),
                &:nth-child(3) {
                    text-align: center;
                }
                &:nth-child(4){
                    padding-top: 3rem;
                }
            }
            .footer-contacts .icon {
                margin-bottom: 2rem;
            }
        }
    }
    @include bdown(md) {
        .footer-top .container {
            .row {
                display: flex;
                .col {
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                    &:nth-child(1) {
                        flex-basis: 100%;
                    }
                    &:nth-child(2) {
                        flex-basis: 50%;
                    }
                    &:nth-child(3) {
                        flex-basis: 50%;
                    }
                    &:nth-child(4) {
                        flex-basis: 100%;
                    }
                    .footer-social-links {
                        padding-left: 0;
                        justify-content: center;
                    }
                }
            }
            .footer-phone {
                position: static;
                width: auto;
                display: inline-flex;
                span {
                    padding-top: 0;
                }
                img {
                    max-height: 70px;
                }
            }
            .logo-container, .footer-about {
                text-align: center;
                margin-bottom: 1rem;
            }
        }
    }
}